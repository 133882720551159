import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { getS3BackgroundUrl, getS3LogoUrl } from '../../../../utils/s3';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PatientContext } from '../../../../contexts';
import { set } from 'date-fns';

const { REACT_APP_ENV } = process.env

const S3_BUCKETS_VBABY_DEFAULT = {
    logo: 'https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/logo.png',
    staging: 'https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/background.png',
    localhost: 'https://stg-vlab-assets.vlab.live/stg-assista_vlab_live/background.png',
    production: 'https://vlab-assets.vlab.live/assista_vlab_live/background.png'
}

const S3_BUCKETS_MEDSTREAMER_DEFAULT = { 
    logo: 'https://vlab-assets.vlab.live/assista_medstreamer_com/medstreamer.png',
    staging: 'https://vlab-assets.vlab.live/assista_medstreamer_com/background.png',
    localhost: 'https://vlab-assets.vlab.live/assista_medstreamer_com/background.png',
    production: 'https://vlab-assets.vlab.live/assista_medstreamer_com/background.png'
}

const Container = styled.div`
  height: 100vh;
  ${props => css`
        background-image: url(${props.url});
    `}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 24px 24px 0px 24px;
  width: 18rem;
  background-color: #FFFFFF;
`;

const ContainerLogo = styled.div `
  width: 100%;
  height: 72px;
  ${props => css`
        background-image: url(${props.url});
    `}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const ContainerBackgroundLoading = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #B7C6D1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LabelLoading = styled.p`
    width: 198px;
    height: 22px;
    font-style: normal;
    font-size: 20px;
    line-height: 21.76px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
`;

const BoxContainer = ({ children }) => {
  const { settings } = useContext(PatientContext)
  const { is_medstreamer } = settings
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageSettings, setImageSettings] = useState({backgroundUrl: "", logoUrl: ""});
  const { t, i18n} = useTranslation()
  const { id } = useParams()

  useEffect(() => {

    setTimeout(async () => {
      const loadAssets = async () => {
        if (!settings.default) {
          setIsLoaded(false);
    
          const [background, logo] = await Promise.all([getBackground(), getLogo()]);
  
          setImageSettings({
            backgroundUrl: background,
            logoUrl: logo
          });
    
          setIsLoaded(true);
        }
      };
    
      loadAssets();
    }, 500)
  }, [settings])

  const getBackground = async () => {

    const backgroundUrl = await getS3BackgroundUrl(id)
    
    try {
      
      await loadImage(backgroundUrl);  

      return backgroundUrl
    } catch (error) { 
      
      return  is_medstreamer ? S3_BUCKETS_MEDSTREAMER_DEFAULT[REACT_APP_ENV] : S3_BUCKETS_VBABY_DEFAULT[REACT_APP_ENV]
    }
  }

  const getLogo = async () => {
    const logoUrl = await getS3LogoUrl(id)

    try {
      await loadImage(logoUrl)

      return logoUrl

    } catch (error) {
      
      return is_medstreamer ? S3_BUCKETS_MEDSTREAMER_DEFAULT['logo'] : S3_BUCKETS_VBABY_DEFAULT['logo'] 
    }
  }

  const loadImage = async (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    });
  };

  return (  
    isLoaded ?
    <Container url={imageSettings.backgroundUrl}>
      <ContainerForm>
        <ContainerLogo url={imageSettings.logoUrl}></ContainerLogo>
        {children}
      </ContainerForm>
      <div 
        style={{ 
            marginTop: 10, 
            backgroundColor: 'white', 
            padding: '12px 24px', 
            borderRadius: 8, 
            display: 'flex', 
            gap: 2, 
            alignItems: 'center', 
            justifyContent: 'center',
            color: '#5E5E5E',
            width: '18rem',
          }}>
        <span className='selected-language'>{t("changeLan.label")}</span>
        <button 
          className='selected-language-button' 
          style={i18n.language === 'pt-BR' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('pt-BR') }}
          >
            Português
        </button>
        |
        <button 
          className='selected-language-button' 
          style={i18n.language === 'en-US' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('en-US') }}
        >
          English
        </button>
        |
        <button 
          className='selected-language-button' 
          style={i18n.language === 'es' ? { color: '#0145D5', fontWeight: 600 }: {}}
          onClick={() => { i18n.changeLanguage('es') }}
        >
          Español
        </button>
      </div>
    </Container> 
    : 
    <ContainerBackgroundLoading>
      <LabelLoading style={{color: '#ECF2F8', fontWeight: 800}}>  {t('loading')} </LabelLoading>
    </ContainerBackgroundLoading>
  );
};

export default BoxContainer;
