import styled from "styled-components";
import Grid from "@material-ui/core/Grid";


export const SelectedImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;   
    width: 100%;
    color: white;
    text-align: center;
    padding: 20px 0;      
    margin-bottom: 20px;
    border-radius: 8px;

    img {
        max-width: 90%;   
        height: auto;
        border-radius: 8px;
    }

    @media (max-width: 768px) {
        padding: 10px 0;

        img {
            max-width: 100%;
        }
    }
`;

export const ButtonBlue = styled.button`
    background-color: rgb(0, 174, 239);
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 8px;
    font-style: normal;
    color : rgb(255, 255, 255);
    font-size: 13.3px;
    height: 42px;    
    width: 100%;
            & {
            text-transform: none;
        }
        &:hover {
            opacity: 0.7;
            cursor: pointer;
            background-color: #00AEEF;
        }
        &:active{
            background: #ECF2F8;
        }
        &:disabled {
            background: #f2f2f2;
            color: #000305;
            border: 1px solid #DDE5EC;
            cursor: not-allowed;
            pointer-events: none;
            img {
            opacity: 0.3;
            }
        }
    `;

export const GalleryGrid = styled(Grid)`
&& {
    @media (max-width: 768px) {
        .MuiGrid-item {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
    }
}
`;