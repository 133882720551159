export default function DeniedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 22.8C10.6167 22.8 9.31667 22.5375 8.1 22.0125C6.88333 21.4875 5.825 20.775 4.925 19.875C4.025 18.975 3.3125 17.9167 2.7875 16.7C2.2625 15.4834 2 14.1834 2 12.8C2 11.4167 2.2625 10.1167 2.7875 8.90005C3.3125 7.68338 4.025 6.62505 4.925 5.72505C5.825 4.82505 6.88333 4.11255 8.1 3.58755C9.31667 3.06255 10.6167 2.80005 12 2.80005C13.3833 2.80005 14.6833 3.06255 15.9 3.58755C17.1167 4.11255 18.175 4.82505 19.075 5.72505C19.975 6.62505 20.6875 7.68338 21.2125 8.90005C21.7375 10.1167 22 11.4167 22 12.8C22 14.1834 21.7375 15.4834 21.2125 16.7C20.6875 17.9167 19.975 18.975 19.075 19.875C18.175 20.775 17.1167 21.4875 15.9 22.0125C14.6833 22.5375 13.3833 22.8 12 22.8ZM12 20.8C12.9 20.8 13.7667 20.6542 14.6 20.3625C15.4333 20.0709 16.2 19.65 16.9 19.1L5.7 7.90005C5.15 8.60005 4.72917 9.36672 4.4375 10.2C4.14583 11.0334 4 11.9 4 12.8C4 15.0334 4.775 16.925 6.325 18.475C7.875 20.025 9.76667 20.8 12 20.8ZM18.3 17.7C18.85 17 19.2708 16.2334 19.5625 15.4C19.8542 14.5667 20 13.7 20 12.8C20 10.5667 19.225 8.67505 17.675 7.12505C16.125 5.57505 14.2333 4.80005 12 4.80005C11.1 4.80005 10.2333 4.94588 9.4 5.23755C8.56667 5.52922 7.8 5.95005 7.1 6.50005L18.3 17.7Z"
        fill="#8B98A3"
      />
    </svg>
  );
}
