import React, { useEffect, useRef } from "react";
import { ButtonBlue } from "../gallery/style";
import { useTranslation } from "react-i18next";

const CanvasWithFrame = ({ imageSrc, frameSrc, maxSize = 600 }) => {
  const canvasRef = useRef(null);
  const imageCache = useRef(new Map());
  const { t } = useTranslation();

  useEffect(() => {
    const renderCanvas = async () => {
      if (!imageSrc || !canvasRef.current) return;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const loadImage = (src) =>
        new Promise((resolve, reject) => {
          if (imageCache.current.has(src)) {
            resolve(imageCache.current.get(src));
          } else {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = src;

            img.onload = () => {
              imageCache.current.set(src, img);
              resolve(img);
            };
            img.onerror = () => reject(`Erro ao carregar imagem: ${src}`);
          }
        });

      try {
        const img = await loadImage(imageSrc);

        const imgRatio = img.width / img.height;
        let drawWidth, drawHeight;

        if (img.width > img.height) {
          drawWidth = maxSize;
          drawHeight = maxSize / imgRatio;
        } else {
          drawHeight = maxSize;
          drawWidth = maxSize * imgRatio;
        }

        canvas.width = drawWidth;
        canvas.height = drawHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, drawWidth, drawHeight);

        if (frameSrc) {
          const frame = await loadImage(frameSrc);
          ctx.drawImage(frame, 0, 0, drawWidth, drawHeight);
        }
      } catch (error) {
        console.error(error);
      }
    };

    renderCanvas();
  }, [imageSrc, frameSrc, maxSize]);

  const downloadImage = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "image.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  return (
    <div style={{ textAlign: "center" }}>
      <canvas ref={canvasRef} style={{ border: "1px solid black" }} />
      <div>
        <ButtonBlue
          style={{
            padding: "10px 20px",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "24px",
          }}
          disabled={!frameSrc}
          onClick={downloadImage}
        >
          {t("gallery.downloadWithFrame")}
        </ButtonBlue>
      </div>
    </div>
  );
};

export default CanvasWithFrame;
