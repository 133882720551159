import React, { useState, useRef, useEffect } from "react";
import { Grid, Card, CardMedia, Checkbox, IconButton } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import CheckIcon from "@material-ui/icons/Check";
import { useTranslation } from "react-i18next";
import { ButtonBlue, GalleryGrid } from "./style";
import { getFrames } from "../../../../lib/api";
import CanvasWithFrame from "../canvas-with-frame";
import RoundedQuestionIcon from "../../../../components/Icons/RoundedQuestionIcon";
import DeniedIcon from "../../../../components/Icons/DeniedIcon";

const ImageGallery = ({ images, imagesPerPage = 6, setImages }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const imageCache = useRef(new Map());
  const [frames, setFrames] = useState([]);
  const { t } = useTranslation();
  const [imagesState, setImagesState] = useState([]);
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imagesState.slice(indexOfFirstImage, indexOfLastImage);

  useEffect(() => {
    getFramesData();
  }, []);

  useEffect(() => {
    if (images) {
      setImagesState(images);
    }
  }, [images]);

  useEffect(() => {
    currentImages.forEach((image) => {
      if (!imageCache.current.has(image.id)) {
        fetch(image.url)
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            imageCache.current.set(image.id, url);
          })
          .catch(console.error);
      }
    });
  }, [currentPage, currentImages]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getFramesData = async () => {
    const response = await getFrames();
    setFrames(response.data.items);
  };

  const handleSelectImage = (id) => {
    const alreadySelected = selectedImages.find((image) => image.id === id);
    if (alreadySelected) {
      setSelectedImages((prev) => prev.filter((image) => image.id !== id));
    } else {
      const image = imagesState.find((img) => img.id === id);
      setSelectedImages((prev) => [...prev, image]);
    }
  };

  const handleSelectImageToShow = (image) => {
    setSelectedImage(() => imagesState.find((img) => img.id === image));
  };

  const handleDownload = async () => {
    const zip = new JSZip();

    const promises = selectedImages.map((image) => {
      const originalUrl = image.url;
      const urlWithoutParams = originalUrl.split("?")[0];
      const fileName = urlWithoutParams.substring(
        urlWithoutParams.lastIndexOf("/") + 1
      );

      return fetch(originalUrl)
        .then((res) => res.blob())
        .then((blob) => {
          zip.file(fileName, blob);
        });
    });

    await Promise.all(promises);
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "images.zip");
    });
  };

  const handleClearSelection = () => {
    setSelectedImages([]);
  };

  const handleSelectAll = () => {
    setSelectedImages(images);
  };

  return (
    <div>
      {selectedImage && (
        <CanvasWithFrame
          imageSrc={
            imageCache.current.get(selectedImage.id) || selectedImage.url
          }
          frameSrc={selectedImage?.frame}
        />
      )}

      {selectedImage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "36px 0",
          }}
        >
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <h1>{t("gallery.frames")}</h1>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              marginTop: "24px",
            }}
          >
            <div
              style={{
                width: "80px",
                height: "80px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F7F8FC",
                borderRadius: "100px",
              }}
              onClick={() => {
                setImages((prev) => {
                  return prev.map((image) => {
                    if (selectedImage.id === image.id) {
                      return {
                        ...image,
                        frame: "",
                      };
                    }
                    return image;
                  });
                });

                setSelectedImages((prev) => {
                  return prev.map((imageId) => {
                    if (selectedImage.id === imageId.id) {
                      return {
                        ...selectedImage,
                        frame: "",
                      };
                    }
                    return imageId;
                  });
                });
                setSelectedImage({
                  ...selectedImage,
                  frame: "",
                });
              }}
            >
              <DeniedIcon />
            </div>
            {frames.map((frame) => (
              <img
                key={frame.id}
                src={frame.path_icon || frame.path_original}
                alt={frame.name}
                style={{
                  width: "80px",
                  height: "80px",
                  cursor: "pointer",
                  objectFit: "contain",
                  outline: `${
                    selectedImage.frame === frame.path_original
                      ? "3px solid #00aeef"
                      : "none"
                  }`,
                  borderRadius: "100%",
                }}
                onClick={() => {
                  setImages((prev) => {
                    return prev.map((image) => {
                      if (selectedImage.id === image.id) {
                        return {
                          ...image,
                          frame: frame.path_original,
                        };
                      }
                      return image;
                    });
                  });

                  setSelectedImages((prev) => {
                    return prev.map((imageId) => {
                      if (selectedImage.id === imageId.id) {
                        return {
                          ...selectedImage,
                          frame: frame.path_original,
                        };
                      }
                      return imageId;
                    });
                  });
                  setSelectedImage({
                    ...selectedImage,
                    frame: frame.path_original,
                  });
                }}
              />
            ))}
          </div>
        </div>
      )}

      <div
        style={{
          paddingBottom: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ margin: 0 }}>{t("gallery.title")}</h1>
        <div style={{ display: "flex", gap: "16px" }}>
          <span
            onClick={handleSelectAll}
            style={{
              cursor: "pointer",
              color: "gray",
              textDecoration: "underline",
            }}
          >
            {t("gallery.selectAll")}
          </span>
          <span
            onClick={handleClearSelection}
            style={{
              cursor: "pointer",
              color: "gray",
              textDecoration: "underline",
            }}
          >
            {t("gallery.clearSelection")}
          </span>
        </div>
      </div>

      <GalleryGrid container spacing={2}>
        {currentImages.map((image) => (
          <Grid item xs={12} sm={6} md={2} key={image.id}>
            <Card
              style={{
                position: "relative",
                cursor: "pointer",
                outline:
                  selectedImage?.id === image.id ? "3px solid #00aeef" : "none",
                outlineOffset: "0.1px",
              }}
            >
              <CardMedia
                component="img"
                alt={`Image ${image.id}`}
                height="140"
                image={image.url}
                title={`Image ${image.id}`}
                onClick={() => handleSelectImageToShow(image.id)}
                style={{ objectFit: "cover" }}
              />

              <Checkbox
                checked={!!selectedImages.find((item) => item.id === image.id)}
                onChange={() => handleSelectImage(image.id)}
                icon={
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "#ffffffe6",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckIcon style={{ fontSize: "16px", color: "gray" }} />
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "#00aeef",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckIcon style={{ fontSize: "16px", color: "#fff" }} />
                  </div>
                }
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  padding: "0",
                }}
              />
            </Card>
          </Grid>
        ))}
      </GalleryGrid>

      <Pagination
        count={Math.ceil(imagesState.length / imagesPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
        shape="rounded"
      />

      <div style={{ marginTop: 20, textAlign: "center", display: "flex" }}>
        <ButtonBlue
          onClick={() => handleDownload(true)}
          disabled={selectedImages.length === 0}
        >
          {t("gallery.download")}
        </ButtonBlue>
      </div>
    </div>
  );
};

export default ImageGallery;
